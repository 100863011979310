body {
    margin: 0;
    padding: 0;
    border: 4px solid #e65728;
    background-color: #e65728;
    height: 100%;
    width: 100%;
}

.container {
    padding: 0 0.5em 0.5em 0.25em;
    min-height: 550px;
    max-width: 450px;
    margin-left: calc(50% - 200px);
    margin-right: 5px;
}

.main {
    padding: 0 0.5em 0.5em 0.5em;
    background-color: white;
    border-radius: 8px;
}

.onboarding {
    padding: 0 0.5em 0.5em;
    max-width: 450px;
    margin-left: calc(50% - 190px);
    margin-right: 5px;
}

.edit-item-dialog {
    width: 300px;
}

.big-logo {
    background-color: transparent;
    padding: 0;
    min-height: 350px;
    min-width: 1000px;
    margin-left: 55%;
    margin-top: -10%;
    background-image: url("resources/logo-color.svg");
    background-repeat: no-repeat;
    background-attachment: scroll;
}

.welcome {
    width: 100%;
    padding-left: 3em;
    text-align: left;
}

.highlight {
    color: #e65728;
}

.name {
    margin-left: 0.5em;
    margin-top: 0;
}

.logout {
    margin: 2em 0 0 0;
}

.light {
    border: none;
    height: auto;
    margin-top: 0.5em;
    padding-top: 0;
    padding-bottom: 0;
}

.light-button {
    color: grey;
}

.light-button.top-right {
    position: absolute;
    right: 1em;
    top: 1.5em;
}

.light-button:hover {
    color: darkgrey;
}

.badge-button {
    color: lightgrey;
}

.badge-button:hover {
    color: white;
}

.header {
    font-size: larger;
    font-weight: bolder;
    color: rgba(0, 0, 0, .88);
    padding-left: 0;
}

.congrats {
    color: #C1CDC1;
    margin-bottom: 0.5em;
}

.todoButton {
    margin-top: 2em;
    border-radius: 0.5em;
    border-color: #0c63e4;
}

.deleteButton {
    margin-top: 1em;
    border: none;
    color: #7b180c;
    background: transparent;
}

.today-input {

}

.el-tag {
    margin-left: 0.5em;
    cursor: pointer;
}

.ant-row {
    min-height: 2.5em;
}

.status-tag {
    text-align: center;
    display: inline-block;
}

.status-widget {
    display: inline-block;
    margin-left: 1em;
}

.ant-tabs .ant-tabs-tab .anticon {
    margin-right: 0;
}

.share-button {
    font-size: 120%;
    color: darkgrey;
}

.share-button:hover {
    color: black;
}

.ant-tabs-tab {
    padding: 0.5em !important;
}

.invitation-required {
    margin: 1em 3em 1em 3em;
}

.smart-item-control {
    margin-left: -1em;
    margin-right: -1em;
}

.ant-card-body {
    margin-bottom: -2em;
    margin-top: -1em;
}

.ant-card {
    margin-bottom: 1em;
}

@keyframes pulsate {
    0% {
        transform: scale(0.1, 0.1);
        opacity: 0.0;
    }
    50% {
        opacity: 1.0;
    }
    100% {
        transform: scale(1.2, 1.2);
        opacity: 0.0;
    }
}

.sos-button {
    color: #e65728;
    font-weight: bold;
    background-color: white;
    height: 4em;
    border: 1px solid #e65728;
    position: absolute;
    top: 0;
    right: 1em;
}

.sos-button:hover {
    color: white !important;
    background-color: #e65728 !important;
    border: 1px solid #e65728 !important;
}


.sos-dialog .ant-modal-content {
    margin: 0 !important;
    padding: 0 !important;
}

.sos-dialog {
    width: 320px !important;
    position: relative;
    top: 170px;
    left: -25px;
}

@keyframes rotate {
    100% {
        transform: rotate(1turn);
    }
}

.rainbow {
    position: relative;
    z-index: 0;
    width: 320px;
    height: 300px;
    border-radius: 6px;
    overflow: hidden;
    padding: 2rem;
    cursor: none;

    &::before {
        content: '';
        position: absolute;
        z-index: -2;
        left: -50%;
        top: -50%;
        width: 200%;
        height: 200%;
        background-color: #e67735;
        background-repeat: no-repeat;
        background-size: 50% 50%, 50% 50%;
        background-position: 0 0, 100% 0, 100% 100%, 0 100%;
        background-image: linear-gradient(#f8a488, #f8a488), linear-gradient(#e97528, #e97528), linear-gradient(#e64d04, #e64d04), linear-gradient(#d42e00, #d42e00);
        animation: rotate 32s linear infinite;
    }

    &::after {
        content: '';
        position: absolute;
        z-index: -1;
        left: 6px;
        top: 6px;
        width: calc(100% - 12px);
        height: calc(100% - 12px);
        background: white;
        border-radius: 5px;
    }
}


.rainbow::before {
    /* other properties */
    -webkit-animation: rotate 32s linear infinite;
    animation: rotate 32s linear infinite;
}

@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}

@keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}
